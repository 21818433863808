import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
// import RootRoute from '../pages/Root/RootRoute';
import BasePage from '../pages/BasePage/BasePage';
import LandingPage from '../pages/LandingPage/LandingPage';
import { BASE_URL } from '../utils/APIs/api';
import { SELLER_WEBSITE_BY_SEARCH_WEBSITE } from '../utils/APIs/constants';

const OpenRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={<LandingPage />}
                errorElement={<ErrorPage />}
                loader={async ({ params }) => {
                    const res = await fetch(
                        BASE_URL +
                            SELLER_WEBSITE_BY_SEARCH_WEBSITE +
                            `?url=https://${window.location.host}`
                    );
                    if (res.status === 404) {
                        throw new Response('Not Found', { status: 404 });
                    }
                }}
            />
            {/* <Route path="/_" element={<LandingPage />} /> */}
            <Route path="*" element={<ErrorPage />} />
            <Route path="/not-found" element={<ErrorPage />} />
        </Routes>
    );
};

export default OpenRoutes;
