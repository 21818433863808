// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';
// import rootSaga from './rootSaga';

const history = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
        history,
    });

// const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer(routerReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(routerMiddleware),
    // getDefaultMiddleware().concat(routerMiddleware).concat(sagaMiddleware),
});

// sagaMiddleware.run(rootSaga);

export const reduxHistory = createReduxHistory(store);

export type IRootState = ReturnType<typeof store.getState>;

export default store;
