import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function ErrorPage() {
    const { state } = useLocation();
    const { message } = state || { message: '' };
    React.useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                window.location.href = 'https://www.elynker.com/homepage';
            }, 600);
            return () => clearTimeout(timer);
        }
    }, [message]);
    const error = useRouteError();
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        // error is type `ErrorResponse`
        errorMessage = error.data?.message || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = '';
    }
    console.log('message', message);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>
                Sorry, an unexpected error has occurred. Please try with valid
                website URL.
            </p>
            <p>
                <i>{!!message ? message : errorMessage}</i>
            </p>
        </div>
    );
}
