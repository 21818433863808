import axiosInstance from './api';
import {
    POST_CONTACT_US_FORM,
    SELLER_WEBSITE_BY_SEARCH_WEBSITE,
} from './constants';

export const getUserDataByHostAndDomain = (hostname) => {
    return axiosInstance.get(SELLER_WEBSITE_BY_SEARCH_WEBSITE + `?${hostname}`);
};

export const postContactUsApi = async (payload) => {
    let res = await axiosInstance.post(POST_CONTACT_US_FORM, payload);
    // console.log("===postEnquiryApi ", res.data);
    return res?.status === 201 || res?.status === 200 ? res.data : false;
};
