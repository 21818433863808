import React, { useEffect } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import AppMain from './app/AppMain';
import ReactGA from 'react-ga4';
import { injectStore } from './utils/APIs/api';
import ErrorPage from './pages/ErrorPage/ErrorPage';
const gaId = process.env.REACT_APP_VENDOR_APP_GA_ID;
if (gaId) {
    ReactGA.initialize(gaId);
} else {
    console.error('GA ID is not defined');
}

const router = createBrowserRouter([
    // match everything with "*"
    { path: '*', element: <AppMain />, errorElement: <ErrorPage /> },
]);

function App() {
    useEffect(() => {
        injectStore(store);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    return (
        <div data-testid={'rootApp'}>
            <button hidden aria-hidden="true">
                rootAppBtn
            </button>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </div>
    );
}

export default App;
