// src/app/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import templateReducer from '../features/template/templateSlice';
import userReducer from '../features/user/userSlice';

const rootReducer = (routerReducer: unknown) =>
    combineReducers({
        router: routerReducer,
        template: templateReducer,
        user: userReducer,
    });

export default rootReducer;
