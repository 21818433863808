import React, { memo, useEffect } from 'react';
import OpenRoutes from '../navigation';

function AppMain() {
    useEffect(() => {}, []);

    return (
        <div data-testid={'rootAppMain'}>
            <OpenRoutes />
        </div>
    );
}

export default memo(AppMain);
