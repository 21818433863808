// src/features/template/templateSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export const TEMPLATE_SLICE = 'TEMPLATE_SLICE';

const templateSlice = createSlice({
    name: TEMPLATE_SLICE,
    initialState: { templateId: null },
    reducers: {
        setTemplateId: (state, action) => {
            state.templateId = action.payload;
        },
    },
});

export const { setTemplateId } = templateSlice.actions;
export default templateSlice.reducer;
