// src/features/user/userSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export const USER_SLICE = 'USER_SLICE';

const templateSlice = createSlice({
    name: USER_SLICE,
    initialState: { userData: null },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
    },
});

export const { setUserData } = templateSlice.actions;
export default templateSlice.reducer;
