import { BASE_URL, UPLOAD_BASE_URL } from './api';

export const SELLER_WEBSITE_BY_SEARCH_WEBSITE = `${BASE_URL}/seller-website/search/website/`;
export const COMPANY_LOGO_BASE_URL = `${UPLOAD_BASE_URL}/uploads/company/company_logo/`;
export const PRODUCT_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/products/products_images/`;
export const SOCIAL_MEDIA_IMAGE_PATH = `${UPLOAD_BASE_URL}/uploads/social/social_images/`;
export const POST_CONTACT_US_FORM = '/business-leads';

// Dialog message
export const DIALOG_TITLE = 'elynker';
export const CONTACT_US_SUCCESS =
    'We have received your request, we will be revert back to you soon';
export const CONTACT_US_ERROR =
    'Please try again, Your request is not submitted';
