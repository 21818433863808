import axios from 'axios';
import { redirect } from 'react-router-dom';

export const BASE_URL = process.env.REACT_APP_VENDOR_APP_BASE_URL;
export const UPLOAD_BASE_URL = process.env.REACT_APP_VENDOR_APP_UPLOAD_BASE_URL;

export const BASE_URL_ENV = process.env.REACT_APP_VENDOR_APP_BASE_URL;

console.log('BASE_URL', BASE_URL_ENV);

const axiosInstance = axios.create({
    baseURL: BASE_URL, // our API base URL
});

let store;

export const injectStore = (_store) => {
    store = _store;
};

axiosInstance.interceptors.request.use(
    (config) => {
        console.log('BASE_URL', BASE_URL_ENV);
        if (store) {
            console.log('Store available...');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        if (error?.response && error?.response?.status === 401) {
            console.error('Unauthorized access');
            clearAllData();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;

function clearAllData() {
    localStorage.clear();
    redirect('/');
}
